import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap';

import './offcanvas';
import './smoothScroll';
// import './modal';

// import './aos';

// import './scrollspy';

import './readMore';

import './watchForHover';

import './browserSync';

