$(document).ready(function($) {

	let triggers = $('.module-two-colums__column__content__read-more__trigger');

	$(triggers).click(toggle);

	function toggle() {
		let content = $(this).find('+ .module-two-colums__column__content__read-more__content');

		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(content).removeClass('active');
		} else {
			$(this).addClass('active');
			$(content).addClass('active');
		}
	}

});
