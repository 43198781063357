$(document).ready(function($) {

	$('.scrollToTop').click(scrollToTop);
	$('.scroll-to-next').click(scrollToNext);
	$('a[href*="/#"]').click(scrollTo);

	const scrollUpBtn = $('.to-top-btn');
	$(window).scroll(showHideScrollUpBtn);

	// Methods
	function scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 600);
		return false;
	}



	function scrollTo(event) {

		let attr = $.attr(this, 'href');

		try {

			event.preventDefault();

			let attrSplit = attr.split('/');

			let $selector = $(attrSplit[attrSplit.length - 1]);

			if($selector.length) {
				$('html, body').animate({
					scrollTop: $selector.offset().top
				}, 500);
			}
			else {
				$(location).attr('href', attr);
			}

		} catch(e) {
			$(location).attr('href', attr);
		}

	}



	function scrollToNext(event) {

		try {

			console.log(window.innerWidth);

			event.preventDefault();

			let $selector = $(this).closest('.module-wrapper').next('.module-wrapper').find('.anchor');

			if($selector.length) {
				$('html, body').animate({
					scrollTop: $selector.offset().top - (575.98 < window.innerWidth ? 47 : 23)
				}, 500);
			}

		} catch(e) {
			console.log(e);
		}

	}

	function showHideScrollUpBtn() {
		const scrollBtnShowHeight = ($(document).height() / 8 * 3) - $(window).innerHeight();
		if (window.scrollY >= scrollBtnShowHeight && $(scrollUpBtn).hasClass('to-top-btn--inactive')) {
			$(scrollUpBtn).removeClass('to-top-btn--inactive');
		} else if (window.scrollY < scrollBtnShowHeight && !$(scrollUpBtn).hasClass('to-top-btn--inactive')) {
			$(scrollUpBtn).addClass('to-top-btn--inactive');
		}
	}

});
